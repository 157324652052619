* {
  font-family: Muli;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  /* wtf? */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  min-height: calc(100vh - 313px);
}

@media (max-width: 768px) {
  .wrapper {
    min-height: 0;
  }
}

.height-to-content .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  height: fit-content !important;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  position: absolute;
  top: 0;
  color: #c4c4c4;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

#globalModal500Error {
  display: flex;
  justify-content: center;
  align-self: center;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes move {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes moveShow {
  from {
    position: relative;
    left: -100%;
  }
  to {
    position: relative;
    left: 0;
  }
}

[data-lvl-global] {
  z-index: 100;
  animation: move 0.15s linear 1;
}

[data-lvl-local] {
  z-index: 90;
  animation: move 0.15s linear 1;
}

[data-lvl-global] > div,
[data-lvl-local] > div {
  position: relative;
  animation: moveShow 0.15s linear 1;
}

[data-lvl-tooltip-decoration-front] {
  z-index: 81;
}

[data-lvl-tooltip] {
  z-index: 80;
}

[data-lvl-tooltip-decoration-backend] {
  z-index: 79;
}

[data-lvl-relative] {
  z-index: 70;
}

[data-lvl-component] {
  z-index: 10;
}

#globalModal500Error,
#round-modal,
#asset-preview {
  z-index: 1005;
}

#sun-editor-portal {
  border: none;
}

@media screen and (max-width: 1450px) {
  .sun-editor-common ul.se-menu-list > li:first-child {
    margin-right: 10px !important;
    margin-left: 5px !important;
  }

  .se-toolbar.sun-editor-common ul.se-menu-list > li:nth-child(3n) {
    margin-right: 10px !important;
  }

  .se-toolbar.sun-editor-common ul.se-menu-list > li:last-child {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 1326px) {
  .se-toolbar.sun-editor-common ul.se-menu-list > li > .se-btn.se-btn-select.se-btn-tool-format.se-tooltip {
    width: 175px;
  }
}

@media screen and (max-width: 1180px) {
  .se-toolbar.sun-editor-common ul.se-menu-list > li > .se-btn.se-btn-select.se-btn-tool-format.se-tooltip {
    width: 100px;
  }

  .se-toolbar.sun-editor-common ul.se-menu-list > li > .se-btn {
    width: 30px;
  }
}

svg.flag {
  width: 20px;
  height: 18px;
  margin-right: 5px;
}

svg.flag-small {
  width: 20px;
  height: 10px;
  margin-right: 5px;
}

.showPortal {
  animation: showPortal 0.1s cubic-bezier(1, 0.01, 1, -0.1);
}
@keyframes showPortal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.__se__float-left {
  float: left;
}
.__se__float-right {
  float: right;
}
.__se__float-center {
  float: center;
}
.__se__float-none {
  float: none;
}

.se-dialog-inner {
  display: flex;
}
.se-dialog-content {
  width: 675px !important;
  height: 520px;
  border-radius: 4px;
  max-width: auto !important;
  margin: auto !important;
}
.se-dialog-header {
  background: #f6f7fc;
  border-radius: 4px;
  padding: 20px 30px;
  height: 70px !important;
}
.se-modal-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: #181818;
}
.sun-editor-editable {
  min-height: 200px !important;
  height: fit-content !important;
}

.lazy-loader {
  width: 100vw;
  height: 100vh;
  background-color: #f7f9fb;
}
